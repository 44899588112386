/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800&family=Manrope:wght@200;300;400;500;600;700&family=Montserrat:ital,wght@0,400;0,500;1,300;1,400&family=Poppins:wght@200;300;400;500;600;700;800&display=swap");
@import "bootstrap/scss/bootstrap";
@import "~@mdi/font/css/materialdesignicons.min.css";
@import "ngx-toastr/toastr";
@import "@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";

:root {
  --white-bg-color: #ffffff;
  --gray-bg-color: #f8f8f8;
  --nav-bg-color: #ffffff;
  --title-dash-color: #124d49;
  --content-title-color: rgba(0, 0, 0, 0.5);
  --content-black-color: #000000;
  --placeholder-color: gray;
  --logo-bg-color: #52d627;
  --table-th-color: #808192;
  --table-td-color: #484964;
  --footer-color: #0a1832;
  --cards-p-color: #124d49;
  --mdi-doc-color: #6c757d;
  --content-border-color: #eeeef2;
  --details-black-color: #000000;
  --details-title-color: #124d49;
  --hr-color: gray;
  --li-chart-color: #707070;
  --axis-label-color: #333333;
  --h6-color: #000000;
  // --axis-label-color: #666666;
  // --axis-line-color: #cccccc;
}

// .dark-theme {
//   --white-bg-color: #333333;
//   --gray-bg-color: #333333;
//   --nav-bg-color: #000000;
//   --title-dash-color: #ffffff;
//   --content-title-color: #23e23d;
//   --content-black-color: #ffffff;
//   --placeholder-color: #ffffff;
//   --logo-bg-color: #23e23d;
//   --table-th-color: #ffffff;
//   --table-td-color: #ffffff;
//   --footer-color: #ffffff;
//   --cards-p-color: #ffffff;
//   --mdi-doc-color: #ffffff;
//   --content-border-color: #333333;
//   --details-black-color: rgb(211, 208, 208);
//   --details-title-color: #23e23d;
//   --hr-color: #ffffff;
//   --li-chart-color: #ffffff;
//   --axis-label-color: #999999;
//   --h6-color: #23e23d;
//   // --axis-line-color: #666666;
// }

html,
body {
  margin: 0px;
  padding: 0px;
  font-family: "Poppins", sans-serif;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  transition: background-color 0.3s ease;
  scroll-behavior: smooth; 
  width: 100%;
  height: 100%;
  // max-width: 100%;
  // overflow: hidden;
  // zoom: 80%;
}
hr {
  color: var(--hr-color);
}
.verification-modal {
  background-color: rgba(255, 255, 255, 1); /* White with some opacity */
}
.owl-dt-inline-container,
.owl-dt-popup-container {
  position: relative;
  width: 15.5em;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.owl-dt-calendar-control {
  display: flex;
  align-items: center;
  font-size: 14px;
  width: 100%;
  padding: 0.5em;
  color: #000000;
  height: 200px;
}
.owl-dt-calendar-table .owl-dt-calendar-cell-selected {
  color: rgba(255, 255, 255, 0.85);
  background-color: #52d627;
}
.owl-dt-timer {
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 12px;
  height: 7em;
  padding: 0.5em;
  outline: none;
}
.owl-dt-container-control-button {
  font-size: 14px;
  font-family: inherit;
  width: 50%;
  height: 100%;
  border-radius: 0;
  margin-bottom: 10px;
}
.pages-wrapper {
  width: 100%;
  // max-width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  // max-height: 100vh;
  margin: 0 auto;
  // background-color: aqua;
  // padding: 20px;
  // overflow-y: hidden;
}
.accordion {
  background: var(--white-bg-color);
}
/* styles.css or styles.scss */
.cdk-overlay-container {
  z-index: 1060 !important; /* Ensure the overlay container is above Bootstrap modal */
}

.cdk-overlay-connected-position-bounding-box {
  z-index: 1061 !important; /* Ensure the date picker itself is above the overlay container */
}
